import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import NavInner from '../NavInner/NavInner';
import './SupplierQualifyPage.css';

const SupplierQualifyPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={2}>
        <Grid item lg={9} md={9} sm={12}>
          <h1>Qualify to be a Supplier</h1>

          <p className="introduction">
            Good contract performance and the ability of suppliers to meet the quality, safety, sustainability and cost requirements begins with the selection of competent and capable suppliers.
          </p>

          <p>
            Sherritt&#39;s assessment process involves two steps:
          </p>

          <h3>1. Supplier Registration</h3>

          <ul>
            <li>
              <strong>Review the applicable Sherritt policies, procedures, and requirements for doing business with Sherritt: <Link to="/Supplier-Resources">Supplier Resources</Link> area. If interested in doing business with Sherritt, proceed to the next step.</strong>
            </li>
            <li>
              Complete <Link to="/Supplier-Application-Form">Supplier Application Form.</Link>
            </li>
            <li>
              A notification will be issued to your company upon receipt of your Supplier Application Form.
              <ul>
                <li>
                  <i>Completion of the Supplier Application Form does not guarantee your company is approved for work with Sherritt.</i>
                </li>
              </ul>
            </li>
            <li>
              Supply Chain Management will review your company’s capabilities against Sherritt’s business needs.
              <ul>
                <li>
                  <i>The Information supplied will remain on file for up to 12 months.</i>
                </li>
              </ul>
            </li>
          </ul>

          <h3>2. Supplier Assessment</h3>

          <ul>
            <li>
              If your company’s Supplier Application Form aligns to business objectives and priorities your company will receive an invitation letter to register in the Pre-Qualification System.
            </li>
{/*             <li>
              ComplyWorks is providing services for Sherritt pre-qualification.
            </li> */}
            <li>
              Your company will be assessed within the Pre-Qualification System on quality, health, safety and environment, sustainability, commercial and financial requirements. 
            </li>
            <li>
              Based on the assessment, your company may be approved for work with Sherritt Fort Site.
              <ul>
                <li>
                  <i>Being listed on the Approved List does not guarantee your company work.</i>
                </li>
              </ul>
            </li>
          </ul>
        </Grid>

        <Grid item lg={3} md={3} sm={6}>
          <NavInner/>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplierQualifyPage;
