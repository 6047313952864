import React from 'react';
import Grid from '@material-ui/core/Grid';
import './PoliciesPage.css';
import patheSign from './pathe-signature.png';
import woodSign from './wood-signature.png';

const PoliciesPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <h1>Environment, Health, Safety &#38; Sustainability Policy</h1>
              <p>The EHS&#38;S Policy applies to all areas of environment, health, safety and sustainability including but not limited to
                worker health &#38; safety encompassing physical, psychological, and social well-being, public safety,
                resource usage, biodiversity, stakeholder engagement, social performance, responsible production, compliance,
                and emergencies.
              </p>
              <p> This Policy applies to all wholly-owned Sherritt’s operations, its subsidiaries, and applicable contractors
                and suppliers.
              </p>
              <p>Every reasonable effort will be made to promote the guiding principles and commitments of the Policy in
                dealings with joint-venture partners and affiliated companies.
              </p>
              <p>Sherritt’s sustainability commitment is articulated in the Sustainability Framework, which consists of the following
                commitments that address the most material sustainability aspects to the Corporation and its stakeholders:
              </p>
              <ol>
                <li>Provide a Safe and Rewarding Workplace</li>
                <li>Operate Ethically</li>
                <li>Demonstrate Environmental Responsibility</li>
                <li>Engage Stakeholders and Benefit Communities</li>
              </ol>
              <p>As part of Sherritt’s Sustainability Framework, the company is committed to providing a safe workplace and
                demonstrating environmental and social responsibility with zero harm and benefiting the communities where the
                company operates being the ultimate goals. Safely and responsibly is how we work, produce products, and
                provide services. Accordingly, Sherritt will:
              </p>
              <ul>
                <li>Proactively identify, assess, and responsibly manage environment, health, and safety hazards and risks
                  associated with our activities, products and services;
                </li>
                <li>Establish and continuously improve management systems for material risks;</li>
                <li>Implement initiatives that prevent illness, injury, and environmental or social harm;</li>
                <li>Establish specific commitments, objectives, and targets to drive continual improvement in EHS&#38;S performance;</li>
                <li>Promote the efficient use of resources, including energy, water, and land, and positively contribute to
                  the conservation of biodiversity as applicable where Sherritt operates;
                </li>
                <li> Ensure compliance with the legal and other requirements where the company operates and with
                  the Company’s EHS&#38;S policies and procedures;
                </li>
                <li>Maintain adequate process safety management and loss prevention systems;</li>
                <li>Maintain public safety and protection of the environment in the event of an emergency in and
                  around our sites through effective risk management, active communication, and ongoing community or
                  local government engagement as applicable;
                </li>
                <li>Regularly measure, evaluate, and communicate our EHS&#38;S management practices and performance;</li>
                <li>Ensure that employees, contractors, and suppliers are aware, understand, and are capable of fulfilling the
                  company’s sustainability commitments; and,
                </li>
                <li>Publicly disclose Sherritt’s performance in a transparent fashion.</li>
              </ul>
              <p>Sherritt’s management is accountable for EHS&#38;S performance and for providing the necessary
                management systems and resources to ensure full compliance with this policy. Employees, contractors,
                and suppliers are accountable for participating in the implementation of this policy and adhering to its commitments.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br/><br/>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <h1>Human Rights Policy</h1>
              <p>The purpose of the Human Rights Policy is to affirm Sherritt International Corporation’s commitment to operate
                in a way that respects human rights as set forth in the United Nations (UN) Universal Declaration of Human
                Rights (UDHR). The Policy applies to all wholly-owned Sherritt’s operations, its subsidiaries, and applicable
                contractors and suppliers.
              </p>
              <ul>
                <li>Sherritt will operate its business in a way that respects human rights as set forth in the UDHR and
                  internationally recognized human rights frameworks. The company will put in place systems and controls
                  consistent with the UN Guiding Principles on Business and Human Rights to ensure that Sherritt and its
                  employees are not directly or indirectly complicit in the violation of human rights.
                </li>
                <li>Sherritt commits to respecting the rights of all stakeholders, with an emphasis on the rights of
                  vulnerable individuals, groups and communities that may be impacted by its operations; including, but not
                  limited to, children, women, and indigenous peoples.
                </li>
                <li>Sherritt will comply with local legislation or corporate policies and procedures, whichever is more stringent.</li>
                <li>Sherritt will establish and maintain effective grievance mechanisms at the site level to receive, investigate
                  and report on any concerns from those who believe their rights have been infringed upon or that have the
                  potential or may be perceived to have a negative impact on human rights. This mechanism shall allow
                  for stakeholders to be kept informed, while guaranteeing anonymity of those who report any violations.
                </li>
                <li>Sherritt will ensure that personnel or human resources policies and practices at its operations respect the
                  fundamental rights of employees as articulated by the International Labour Organization’s Declaration on
                  Fundamental Principles and Rights at Work, including:
                  <ul>
                    <li>The freedom of association and the effective recognition of the right to collective bargaining;</li>
                    <li>The elimination of all forms of forced or compulsory labour, including but not limited to servitude, human trafficking and exploitation;</li>
                    <li>The effective abolition of child labour, by complying with the Child Rights and Security
                      Checklist, and the Children’s Rights and Business Principles;
                    </li>
                    <li>The elimination of discrimination in respect of employment and occupation; and,</li>
                    <li>The establishment of constructive mechanisms where employees can seek redress for
                      violations of any of these rights without recrimination.
                    </li>
                  </ul>
                </li>
                <li>Sherritt will ensure that the security arrangements for the company’s operations respect human rights and
                  are consistent with the Voluntary Principles on Security and Human Rights.
                </li>
                <li>Sherritt expects contractors, consultants, suppliers, and joint venture partners to share the company’s
                  human rights commitments.
                </li>
                <li>Sherritt commits to informing all relevant stakeholders, including contractors and suppliers of our Human
                  Rights Policy, our expectations and requirements, and undertaking our own due diligence of their practices,
                  policies, and reputation, as appropriate.
                </li>
              </ul>
              <p>Sherritt’s management is accountable for providing the necessary management systems and resources to ensure
                full compliance with this policy. Employees, contractors, and suppliers are accountable for participating in the
                implementation of this policy and adhering to its commitments.
              </p>
              <p>Every reasonable effort will be made to promote the guiding principles and commitments of the Policy in
                dealings with joint-venture partners and affiliated companies.
              </p>
              <p>Upholding these commitments is the responsibility of all employees, contractors, and suppliers.</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PoliciesPage;
