import React from 'react';
import Grid from '@material-ui/core/Grid';
import NavInner from '../NavInner/NavInner';

const SupplierPotentialAndExistingPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={2}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <h1>Supplier Resources</h1>

          <p>
            Sherritt expects all Suppliers to adhere to all applicable Sherritt policies.  Sherritt has listed key policies below to help Suppliers gain an understanding of requirements for working with Sherritt. Sherritt may add other policies and requirements as it deems necessary.​
          </p>

          <ul>
            <li><a href="/EHSS-HR-Policies">Environment, Health, Safety &#38; Sustainability Policy, Human Rights Policy</a></li>
            <li><a href="/Life-Safety-Rules">Life Safety Rules</a></li>
          </ul>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <NavInner/>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplierPotentialAndExistingPage;
