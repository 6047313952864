import React, { useState } from 'react';
import clsx from 'clsx';
import NavMenu from './NavMenu/NavMenu';
import NavHeader from './NavHeader/NavHeader';
import NavFooter from './NavFooter/NavFooter';
import './Layout.css';

const Layout = (props) => {
  const [isHighContrast, setHighContrast] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);

  const onHighContrastToggle = () => {
    setHighContrast(!isHighContrast);
  };

  const onMenuToggle = () => {
    setShowMenu(!isShowMenu);
  };

  return (
    <div className="page-container">
      <div className={clsx('page-menu', { 'page-menu-visible': isShowMenu })}>
        <NavMenu highContrast={isHighContrast} showMenu={isShowMenu} onHighContrastToggle={onHighContrastToggle} onMenuToggle={onMenuToggle} />
      </div>
      <div className={clsx('page-content', { 'page-content-shifted': isShowMenu })}>
        <div className={clsx({ 'page-content-overlay': isShowMenu })}>
        </div>
        <NavHeader showMenu={isShowMenu} onMenuToggle={onMenuToggle} />
        <div className={clsx({ 'page-high-contrast': isHighContrast })}>
          {props.children}
        </div>
        <NavFooter />
      </div>
    </div>
  );
};

export default Layout;
