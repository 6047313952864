import React from 'react';
import Grid from '@material-ui/core/Grid';
import './LifeSafetyRulesPage.css';
import rule01 from './rule-001.png';
import rule02 from './rule-002.png';
import rule03 from './rule-003.png';
import rule04 from './rule-004.png';
import rule05 from './rule-005.png';
import rule06 from './rule-006.png';
import rule07 from './rule-007.png';
import rule08 from './rule-008.png';
import rule09 from './rule-009.png';
import rule10 from './rule-010.png';

const LifeSafetyRulesPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container item spacing={2}>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <h1>Life Safety Rules</h1>
              <h3>Sherritt is committed to providing a safe workplace.</h3>
              <p>The Life Safety Rules program reinforces critical aspects of existing health and safety policies
                and practices that present a higher risk of serious injuries, illnesses and fatalities. The
                expectation is that under no circumstances (cost, time, effort, etc.) will a Life Safety Rule be
                violated. For the Life Safety Rules program to be effectively implemented at the Fort Site, it is
                imperative that all workers know, understand, and follow the Life Safety Rules. The Life Safety
                Rules must be applied from the top down, with support coming from the highest levels of
                management at Sherritt. It is important that all violations of Life Safety Rules are managed
                consistently, from investigation, corrective actions and discipline.
              </p>
              <h3>The following are the 10 Life Safety Rules …</h3>
              <ol>
                <li>Work with a valid Safe Work Permit</li>
                <li>Verify isolations before work begins</li>
                <li>Obtain authorization before entering a confined space</li>
                <li>Follow the requirements of the Traffic Management Program</li>
                <li>Protect yourself against a fall when working at heights</li>
                <li>Use and abide by barrier tape and tagging</li>
                <li>Use proper personal protective equipment (PPE)</li>
                <li>Obtain authorization before overriding or disabling safety critical equipment</li>
                <li>Do not walk under a suspended load</li>
                <li>Ensure you are always Fit-For-Work</li>
              </ol>
              <br/>
              <br/>
            </Grid>
          </Grid>
          <Grid container item spacing={2} justify="center">
            <Grid item>
              <img alt="Life Satety Rules" src={rule01} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule02} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule03} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule04} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule05} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule06} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule07} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule08} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule09} />
            </Grid>
            <Grid item>
              <img alt="Life Satety Rules" src={rule10} />
            </Grid>
          </Grid>
          <br/>
        </Grid>
      </Grid>
    </div>
  );
};

export default LifeSafetyRulesPage;
