import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import headerLogo from './sherritt-logo.png';
import headerImage1 from './sherritt-header-0001.jpg';
import headerImage2 from './sherritt-header-0002.jpg';
import headerImage3 from './sherritt-header-0003.jpg';
import headerImage4 from './sherritt-header-0004.jpg';
import headerImage5 from './sherritt-header-0005.jpg';
import breadcrumbsLinks from './links.png';
import './NavHeader.css';

const NavHeader = (props) => {
  const location = useLocation();
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);

  const onLinksClick = (event) => {
    let target = event.currentTarget;
    setPopperOpen(!popperOpen);
    setPopperAnchor(target);
  };

  const headerImagePageMapping = {
    '/': headerImage1,
    '/Qualify-To-Be-A-Supplier': headerImage2,
    '/Supplier-Resources': headerImage3,
    '/Supplier-Application-Form': headerImage4,
    '/Supplier-Application-Form-Success': headerImage4,
    '/FAQs': headerImage4,
    '/Life-Safety-Rules': headerImage3,
    '/EHSS-HR-Policies': headerImage5
  };

  const breadCrumbPageMapping = {
    '/': {name: 'Doing Business With Sherritt', path: []},
    '/Qualify-To-Be-A-Supplier': {name: 'Qualify to be a Supplier', path: ['/']},
    '/Supplier-Resources': {name: 'Supplier Resources', path: ['/']},
    '/Supplier-Application-Form': {name: 'Supplier Application Form', path:['/']},
    '/Supplier-Application-Form-Success': {name: 'Supplier Application Form', path: ['/']},
    '/FAQs': {name: 'FAQs', path: ['/']},
    '/Life-Safety-Rules': {name: 'Life Safety Rules', path: ['/Supplier-Resources', '/']},
    '/EHSS-HR-Policies': {name: 'Environment, Health, Safety & Sustainability Policy, Human Rights Policy', path: ['/Supplier-Resources', '/']}
  };

  const createElement = (islink, key, path, name, links) => {
    links.push(<span key={key++}>&nbsp; &gt; &nbsp;</span>);
    if (islink) {
      links.push(<a key={key++} href={path}>{name}</a>);
    } else {
      links.push(<span key={key++} className="nav-breadcrumb-current">{name}</span>);
    }

    return key;
  }

  const createBreadCrumbs = (key, pathname, links, islink) => {
    const breadCrumbMapping = breadCrumbPageMapping[pathname];
    if (breadCrumbMapping.path.length === 0) {
      key = createElement(islink, key++, pathname, breadCrumbMapping.name, links);
    }
    else {
      key = createBreadCrumbs(key++, breadCrumbMapping.path[0], links, true);
      key = createElement(islink, key++, pathname, breadCrumbMapping.name, links);
    }

    return key;
  }

  // TODO: Make this better.
  const buildBreadCrumbs = () => {
    let links = [
      <a key={0} href={process.env.REACT_APP_NAV_LINK_HOME}>Home</a>,
    ];

    createBreadCrumbs(1, location.pathname, links, false);
    return links;
  };

  useEffect(() => {
    let parts = ['Sherritt International'];
    const currentPage = breadCrumbPageMapping[location.pathname].name;
    if (currentPage) {
      parts.push(currentPage);
    }

    document.title = parts.join(' - ');
  });

  return (
    <div className="nav-header-container">
      <div className="nav-header">
        <div className={clsx('nav-header-menu-toggle', { 'nav-header-menu-visible': !props.showMenu })} onClick={props.onMenuToggle}>
          <div className="nav-header-menu-bars">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
          <div className="nav-header-menu-text">
            Menu
          </div>
        </div>
        <div className="nav-header-logo">
        <a href={process.env.REACT_APP_NAV_LINK_HOME}><img alt="Sherritt Logo" src={headerLogo} /></a>
        </div>
        <div className="nav-header-image">
          <img alt="Heading" src={headerImagePageMapping[location.pathname] || headerImage5} />
        </div>
      </div>

      <div className="nav-breadcrumbs-container">
        <div className="nav-breadcrumbs">
          {buildBreadCrumbs()}
        </div>

        <div className="nav-breadcrumbs-links" onClick={onLinksClick}>
          <img alt="Breadcrumb links" src={breadcrumbsLinks} />
        </div>

        <Popper open={popperOpen} anchorEl={popperAnchor} placement="bottom-end">
          <div className="nav-breadcrumbs-links-popper">
            <span className="nav-breadcrumbs-links-popper-title">Related Links</span>
            <ul className="nav-breadcrumbs-links-popper-list">
              <li><a href={process.env.REACT_APP_NAV_LINK_ABOUT_US}>About Us</a></li>
              <li><a href={process.env.REACT_APP_NAV_LINK_MEDIA_CENTRE}>Media Centre</a></li>
              <li><a href={process.env.REACT_APP_NAV_LINK_CONTACT}>Contact</a></li>
            </ul>
          </div>
        </Popper>
      </div>
    </div>
  );
};

export default NavHeader;
