import React from 'react';
import { Switch, Route } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Layout from './components/Layout';
import BusinessWithSherrittPage from './components/BusinessWithSherrittPage/BusinessWithSherrittPage';
import SupplierQualifyPage from './components/SupplierQualifyPage/SupplierQualifyPage';
import SupplierPotentialAndExistingPage from './components/SupplierPotentialAndExistingPage/SupplierPotentialAndExistingPage';
import SupplierApplicationFormPage from './components/SupplierApplicationFormPage/SupplierApplicationFormPage';
import FAQsPage from './components/FAQsPage/FAQsPage';
import LifeSafetyRulesPage from './components/LifeSafetyRulesPage/LifeSafetyRulesPage'
import PoliciesPage from './components/PoliciesPage/PoliciesPage'
import './custom.css'

const App = () => {
  const notFound = (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <h1>Page Not Found</h1>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Layout>
      <Switch>
        <Route exact path='/' component={BusinessWithSherrittPage} />
        <Route exact path='/Qualify-To-Be-A-Supplier' component={SupplierQualifyPage} />
        <Route exact path='/Supplier-Resources' component={SupplierPotentialAndExistingPage} />
        <Route exact path='/Supplier-Application-Form' component={SupplierApplicationFormPage} />
        <Route exact path='/Supplier-Application-Form-Success' component={() => <SupplierApplicationFormPage isSuccess={true}/>} />
        <Route exact path='/FAQs' component={FAQsPage} />
        <Route exact path='/Life-Safety-Rules' component={LifeSafetyRulesPage} />
        <Route exact path='/EHSS-HR-Policies' component={PoliciesPage} />
        <Route render={() => notFound} />
      </Switch>
    </Layout>
  );
};

export default App;
