import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import useApplicationForm from './UseApplicationForm';
import './SupplierApplicationFormPage.css'

const SupplierApplicationFormPage = (props) => {
  const [inputs, errors, isRLSameAsHOAddress, isSubmitting, handleInputChange, handleRegionalSameAsHeadChange, handleSubmit] = useApplicationForm();

  if (props.isSuccess) {
    return (
      <div className="page-content-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h1>Supplier Application Form</h1>
          </Grid>

          <Grid item xs={12}>
            <p className="introduction">
              Thank you for submitting your Supplier Application Form to Sherritt.
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <form className="application-form" onSubmit={handleSubmit} noValidate autoComplete="off">
            <h1>Supplier Application Form</h1>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>General</h2>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <Paper variant="outlined">
                    <TextField name="Title" error={!!errors.Title} helperText={errors.Title} label="Legal Company Name" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="TradeName" error={!!errors.TradeName} helperText={errors.TradeName} label="Trade Name (if applicable)" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="CorporateRegistrationNumber" error={!!errors.CorporateRegistrationNumber} helperText={errors.CorporateRegistrationNumber} label="Corporate Registration Number" onChange={handleInputChange} variant="outlined" fullWidth />
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <h3>Head Office Location</h3>

                  <Paper variant="outlined">
                    <TextField name="HOStreet" error={!!errors.HOStreet} helperText={errors.HOStreet} label="Street" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="HOCity" error={!!errors.HOCity} helperText={errors.HOCity} label="City" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="HOProvinceorState" error={!!errors.HOProvinceorState} helperText={errors.HOProvinceorState} label="Province/State" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="HOCountry" error={!!errors.HOCountry} helperText={errors.HOCountry} label="Country" onChange={handleInputChange} variant="outlined" fullWidth />
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <h3>Regional Location</h3>
                  <FormControl component="fieldset">
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox name="SameAsHeadOffice" value="Same As Head Office" onChange={handleRegionalSameAsHeadChange} />} label="Same As Head Office" />
                    </FormGroup>
                  </FormControl>
                  <Paper variant="outlined">
                    <TextField name="RLStreet" disabled={isRLSameAsHOAddress} value={inputs.RLStreet || ''} error={!!errors.RLStreet} helperText={errors.RLStreet} label="Street" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="RLCity" disabled={isRLSameAsHOAddress} value={inputs.RLCity || ''} error={!!errors.RLCity} helperText={errors.RLCity} label="City" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="RLProvinceorState" disabled={isRLSameAsHOAddress} value={inputs.RLProvinceorState || ''} error={!!errors.RLProvinceorState} helperText={errors.RLProvinceorState} label="Province/State" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="RLCountry" disabled={isRLSameAsHOAddress} value={inputs.RLCountry || ''} error={!!errors.RLCountry} helperText={errors.RLCountry} label="Country" onChange={handleInputChange} variant="outlined" fullWidth />
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <h3>Contact Information</h3>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <Paper variant="outlined">
                    <TextField name="SupplierTitle" error={!!errors.SupplierTitle} helperText={errors.SupplierTitle} label="Position Title" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="SupplierName" error={!!errors.SupplierName} helperText={errors.SupplierName} label="Name" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="SupplierEmailAddress" error={!!errors.SupplierEmailAddress} helperText={errors.SupplierEmailAddress} label="Email Address" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="SupplierPhoneNumber_x0028_s_x002" error={!!errors.SupplierPhoneNumber_x0028_s_x002} helperText={errors.SupplierPhoneNumber_x0028_s_x002} label="Phone Number(s)" onChange={handleInputChange} variant="outlined" fullWidth />
                  </Paper>
                </Grid>
              </Grid>

{/*               <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.SubscribedToComplyWorks}>
                      <FormLabel component="legend">Is your company subscribed to &quot;ComplyWorks&quot; software?</FormLabel>
                      <RadioGroup name="SubscribedToComplyWorks" onChange={handleInputChange}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText>{errors.SubscribedToComplyWorks}</FormHelperText>
                      <TextField name="SubscribedToComplyWorksIdNumber" error={!!errors.SubscribedToComplyWorksIdNumber} helperText={errors.SubscribedToComplyWorksIdNumber} label="If yes, provide company ID number" onChange={handleInputChange} variant="outlined" fullWidth />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid> */}

              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.ConflictofInterest}>
                      <FormLabel component="legend">Does a Conflict of Interest or potential conflict of interest exist with any Sherritt employees?</FormLabel>
                      <RadioGroup name="ConflictofInterest" onChange={handleInputChange}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText>{errors.ConflictofInterest}</FormHelperText>
                      <TextField name="ConflictofInterestExplanation" error={!!errors.ConflictofInterestExplanation} helperText={errors.ConflictofInterestExplanation} label="If yes, explain" onChange={handleInputChange} variant="outlined" fullWidth />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
              
              <Grid container item spacing={2} xs={12}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.AcknowledgeReadRequirements}>
                        <FormControlLabel control={<Checkbox name="AcknowledgeReadRequirements" value="Acknowledge Read Requirements" onChange={handleInputChange} />} label="Acknowledge your company has read the requirements listed in the Supplier Resources area of Sherritt’s website, Doing Business with Sherritt." />
                        <FormHelperText>{errors.AcknowledgeReadRequirements}</FormHelperText>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Materials/Services</h2>

                <FormLabel component="legend">
                  Select all that apply to your company&#39;s offerings.
                </FormLabel>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <h3>Input Materials &amp; Bulk Chemicals</h3>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Pipeline Products (H2S, Nitrogen, Hydrogen, Domestic Water)" onChange={handleInputChange} />} label="Pipeline Products (H2S, Nitrogen, Hydrogen, Domestic Water)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Industrial Cleaning (Various chemicals supplied by suppliers of the service)" onChange={handleInputChange} />} label="Industrial Cleaning (Various chemicals supplied by suppliers of the service)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Process Chemicals (Molten Sulphur, Anti Caking and dedusting oils, Liquid Alum, Catalysts)" onChange={handleInputChange} />} label="Process Chemicals (Molten Sulphur, Anti Caking and dedusting oils, Liquid Alum, Catalysts)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Operating Materials (Acrysol, Flocculants, Ferrous Sulphate, Sodium Cyanide, Grinding balls, Filter Aid,  Alum - Dry,  SO2)" onChange={handleInputChange} />} label="Operating Materials (Acrysol, Flocculants, Ferrous Sulphate, Sodium Cyanide, Grinding balls, Filter Aid,  Alum - Dry,  SO2)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Water Treatment (Boiler Water, Biological, Resins, Caustic, Lime, Scavengers, Corrosion inhibitors, Salt, Dispersants, PH Control, Liquid Alum, Anti foam agents)" onChange={handleInputChange} />} label="Water Treatment (Boiler Water, Biological, Resins, Caustic, Lime, Scavengers, Corrosion inhibitors, Salt, Dispersants, PH Control, Liquid Alum, Anti foam agents)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Fuels and Lubricants (Compressor oils, Gas, Diesel, greases, specialty oils)" onChange={handleInputChange} />} label="Fuels and Lubricants (Compressor oils, Gas, Diesel, greases, specialty oils)" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="InputMaterialsAndBulkChemicals" value="Industrial Gases (Bulk Argon, calibration gases, welding gases)" onChange={handleInputChange} />} label="Industrial Gases (Bulk Argon, calibration gases, welding gases)" />
                    </FormGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <h3>IT, Transportation, Professional & Facility Services</h3>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Logistics/Transportation Services" onChange={handleInputChange} />} label="Logistics/Transportation Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="EPCM Services" onChange={handleInputChange} />} label="EPCM Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Human Resources (HR) Services" onChange={handleInputChange} />} label="Human Resources (HR) Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Environmental Services" onChange={handleInputChange} />} label="Environmental Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Facilities Maintenance Services" onChange={handleInputChange} />} label="Facilities Maintenance Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="General Consultancy" onChange={handleInputChange} />} label="General Consultancy" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Health, Safety, Security, and Emergency Response Services" onChange={handleInputChange} />} label="Health, Safety, Security, and Emergency Response Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Professional Services / Consultancy" onChange={handleInputChange} />} label="Professional Services / Consultancy" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Earthworks / Construction Services" onChange={handleInputChange} />} label="Earthworks / Construction Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="ITTranspProAndFacServices" value="Individual Consultants / Contingent Staffing" onChange={handleInputChange} />} label="Individual Consultants / Contingent Staffing" />
                    </FormGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <h3>Maintenance, Repair and Operating (MRO), Equipment &amp; Machinery Parts</h3>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Industrial Distributors" onChange={handleInputChange} />} label="Industrial Distributors" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Power Transmission / Bearings Supply" onChange={handleInputChange} />} label="Power Transmission / Bearings Supply" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Electrical Distributors" onChange={handleInputChange} />} label="Electrical Distributors" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Industrial Instrumentation Supply" onChange={handleInputChange} />} label="Industrial Instrumentation Supply" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Packaging Materials" onChange={handleInputChange} />} label="Packaging Materials" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Equipment Parts, Supplies & Rentals" onChange={handleInputChange} />} label="Equipment Parts, Supplies & Rentals" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="PVF / Plumbing / HVAC Distributors" onChange={handleInputChange} />} label="PVF / Plumbing / HVAC Distributors" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Building & Construction Materials" onChange={handleInputChange} />} label="Building & Construction Materials" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Lab Equipment & Suppliers" onChange={handleInputChange} />} label="Lab Equipment & Suppliers" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="MroEquipmAndMachParts" value="Specialty Industrial Products" onChange={handleInputChange} />} label="Specialty Industrial Products" />
                    </FormGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <h3>Plant Maintenance &amp; Specialty Services</h3>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Acid Cleaning, Chemical Cleaning, Hydro Blasting, Vacuum Truck Services" onChange={handleInputChange} />} label="Acid Cleaning, Chemical Cleaning, Hydro Blasting, Vacuum Truck Services" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Asbestos Removal" onChange={handleInputChange} />} label="Asbestos Removal" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Conveyor Belt Splicing and Repair" onChange={handleInputChange} />} label="Conveyor Belt Splicing and Repair" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Electrical, Electronics and Instrumentation" onChange={handleInputChange} />} label="Electrical, Electronics and Instrumentation" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Facilities, HVAC, Plumbing, Roofing, Insulation, Painting" onChange={handleInputChange} />} label="Facilities, HVAC, Plumbing, Roofing, Insulation, Painting" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="General Construction, Concrete, Paving" onChange={handleInputChange} />} label="General Construction, Concrete, Paving" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Inspections" onChange={handleInputChange} />} label="Inspections" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Machining, Metal Fabrication, Pipe Fabrication" onChange={handleInputChange} />} label="Machining, Metal Fabrication, Pipe Fabrication" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Manpower - Trades Welding, Mechanical, Man Watch, Safety Watch" onChange={handleInputChange} />} label="Manpower - Trades Welding, Mechanical, Man Watch, Safety Watch" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Overhead Cranes, Mobile Equipment Repairs" onChange={handleInputChange} />} label="Overhead Cranes, Mobile Equipment Repairs" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Refractories and Acid Brick" onChange={handleInputChange} />} label="Refractories and Acid Brick" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="PlantMaintAndSpecialtyServices" value="Scaffolding - Rental and Installation" onChange={handleInputChange} />} label="Scaffolding - Rental and Installation" />
                    </FormGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>Other</h3>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Identify any materials and or services your company offers that are not listed above:
                    </FormLabel>

                    <TextField name="MaterialsOrServicesNotListed" error={!!errors.MaterialsOrServicesNotListed} helperText={errors.MaterialsOrServicesNotListed} multiline rows={4} onChange={handleInputChange} variant="outlined" fullWidth />
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Value Added Services</h2>

                <Paper variant="outlined">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Identify any Value Added Services such as innovative technologies, solutions or operational efficiencies your company is able to offer to Sherritt in addition to the core capabilities of the categories.
                    </FormLabel>

                    <TextField name="ValueAddedServices" error={!!errors.ValueAddedServices} multiline rows={4} onChange={handleInputChange} variant="outlined" fullWidth />
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>Commercial and Finance</h2>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.WhereCompanyRegistered}>
                      <FormLabel component="legend">Where is your company registered?</FormLabel>
                      <RadioGroup name="WhereCompanyRegistered" onChange={handleInputChange}>
                        <FormControlLabel value="Canada" control={<Radio />} label="Canada" />
                        <FormControlLabel value="US"     control={<Radio />} label="US" />
                        <FormControlLabel value="Other"  control={<Radio />} label="Other" />
                      </RadioGroup>
                      <FormHelperText>{errors.WhereCompanyRegistered}</FormHelperText>
                      <TextField name="CanadaIdProvince" error={!!errors.CanadaIdProvince} helperText={errors.CanadaIdProvince} label="If in Canada, ID province" onChange={handleInputChange} variant="outlined" fullWidth />
                      <TextField name="CompanyRegisteredOtherExplanatio" error={!!errors.CompanyRegisteredOtherExplanatio} helperText={errors.CompanyRegisteredOtherExplanatio} label="If other, explain" onChange={handleInputChange} variant="outlined" fullWidth />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <FormLabel component="legend">List the main banking partners of your company:</FormLabel>
                    <TextField name="BankingPartnersName" error={!!errors.BankingPartnersName} helperText={errors.BankingPartnersName} label="Partner Name" onChange={handleInputChange} variant="outlined" fullWidth />
                    <TextField name="BankingPartnersAddress" error={!!errors.BankingPartnersAddress} helperText={errors.BankingPartnersAddress} label="Address (Street, City, Prov./State, Country)" multiline rows={4} onChange={handleInputChange} variant="outlined" fullWidth />
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.CompanyAssociatedWithUS}>
                      <FormLabel component="legend">Is the company owned, in full or in part, or associated with a US company or US persons?</FormLabel>
                      <RadioGroup name="CompanyAssociatedWithUS" onChange={handleInputChange}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText>{errors.CompanyAssociatedWithUS}</FormHelperText>
                      <TextField name="AssociatedWithUSCompanyName" error={!!errors.AssociatedWithUSCompanyName} helperText={errors.AssociatedWithUSCompanyName} label="If yes, company name" onChange={handleInputChange} variant="outlined" fullWidth />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <FormControl component="fieldset" error={!!errors.CurrencyCompanytransactIn}>
                      <FormLabel component="legend">What currency does your company transact in?</FormLabel>
                      <RadioGroup name="CurrencyCompanytransactIn" onChange={handleInputChange}>
                        <FormControlLabel value="Canadian" control={<Radio />} label="Canadian Dollars" />
                        <FormControlLabel value="US" control={<Radio />} label="US Dollars" />
                        <FormControlLabel value="Euro" control={<Radio />} label="Euro" />
                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                      </RadioGroup>
                      <FormHelperText>{errors.CurrencyCompanytransactIn}</FormHelperText>
                      <TextField name="CurrencyOtherExplanation" error={!!errors.CurrencyOtherExplanation} helperText={errors.CurrencyOtherExplanation} label="If other, explain" onChange={handleInputChange} variant="outlined" fullWidth />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.DoneBusinessWithSherritt}>
                    <FormLabel component="legend">Has your company previously done business with Sherritt?</FormLabel>
                    <RadioGroup name="DoneBusinessWithSherritt" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.DoneBusinessWithSherritt}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>Quality, Health and Safety</h2>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HaveQualityProgramAndManual}>
                    <FormLabel component="legend">Does your company have a Quality Program and Quality Manual?</FormLabel>
                    <RadioGroup name="HaveQualityProgramAndManual" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HaveQualityProgramAndManual}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HaveHealthAndSafetySystem}>
                    <FormLabel component="legend">Does your organization have a documented health &amp; safety management system or program that meets applicable legislation?</FormLabel>
                    <RadioGroup name="HaveHealthAndSafetySystem" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HaveHealthAndSafetySystem}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>Sustainability</h2>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HavePolicyOnEnvironmentalSteward}>
                    <FormLabel component="legend">Does your company have a formal written and implemented policy or policies and management systems that establish social, ethical, and environmental commitments?</FormLabel>
                    <RadioGroup name="HavePolicyOnEnvironmentalSteward" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HavePolicyOnEnvironmentalSteward}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HaveSupplierDiversityGoals}>
                    <FormLabel component="legend">Does your company have supplier diversity goals and/or a diversity and inclusion program?</FormLabel>
                    <RadioGroup name="HaveSupplierDiversityGoals" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HaveSupplierDiversityGoals}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HavePolicyOnHumanRights}>
                    <FormLabel component="legend">Does your company have a formal written and implemented policy on human rights covering topics such as child labour, human trafficking, modern slavery, discrimination, etc.?</FormLabel>
                    <RadioGroup name="HavePolicyOnHumanRights" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HavePolicyOnHumanRights}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.ConductHumanRightsDueDiligence}>
                    <FormLabel component="legend">Does your company conduct human rights due diligence on third-party suppliers or contractors?</FormLabel>
                    <RadioGroup name="ConductHumanRightsDueDiligence" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.ConductHumanRightsDueDiligence}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.OperateThroughConflictAreas}>
                    <FormLabel component="legend">Does your company source from, operate in or transit through conflict Affected and High risk Areas, as defined by the OECD? </FormLabel>
                    <FormLabel component="legend">
                      <i>Definition: Conflict-affected and high-risk areas are identified by the presence of armed conflict, widespread violence or other risks of harm to people. Armed conflict may take a variety of forms, such as a conflict of international or non-international character, which may involve two or more states, or may consist of wars of liberation, or insurgencies, civil wars, etc. High-risk areas may include areas of political instability or repression, institutional weakness, insecurity, collapse of civil infrastructure and widespread violence. Such areas are often characterized by widespread human rights abuses and violations of national or international law.</i>
                    </FormLabel>
                    <RadioGroup name="OperateThroughConflictAreas" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.OperateThroughConflictAreas}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.AffiliatedWithIndigenous}>
                    <FormLabel component="legend">Is your company owned by or affiliated with an Indigenous community (Native American or Canadian Indigenous)?</FormLabel>
                    <RadioGroup name="AffiliatedWithIndigenous" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.AffiliatedWithIndigenous}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.InvolvedInLegalCase}>
                    <FormLabel component="legend">During the last five years, has your company been involved in any legal case or allegations regarding corruption or bribery?</FormLabel>
                    <RadioGroup name="InvolvedInLegalCase" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.InvolvedInLegalCase}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.HaveEnvironmentalIncidents}>
                    <FormLabel component="legend">
                      During the last five years, have any significant environmental incidents occurred in your company or have you received any environmental orders, fines or penalties from the applicable authorities?
                    </FormLabel>
                    <RadioGroup name="HaveEnvironmentalIncidents" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.HaveEnvironmentalIncidents}</FormHelperText>
                    <TextField name="EnviroIncidentsExplanation" error={!!errors.EnviroIncidentsExplanation} helperText={errors.EnviroIncidentsExplanation} label="If yes, provide a brief description of the current status" onChange={handleInputChange} multiline rows={4} variant="outlined" fullWidth />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <FormControl component="fieldset" error={!!errors.MinimumRequirementsWithSuppliers}>
                    <FormLabel component="legend">Does your company include minimum human rights, environmental, health and safety, ethical and social requirements in agreements with your suppliers?</FormLabel>
                    <RadioGroup name="MinimumRequirementsWithSuppliers" onChange={handleInputChange}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormHelperText>{errors.MinimumRequirementsWithSuppliers}</FormHelperText>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <hr />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                  Submit Application Form
                </Button>
              </Grid>

              { errors.fatal &&
                <Grid item xs={12}>
                  <Paper className="error" variant="outlined">
                    Sorry, an error occurred while submitting your application. You can try submitting the form again or <a href={process.env.REACT_APP_NAV_LINK_CONTACT}>contacting us</a>.
                  </Paper>
                </Grid> 
              }
              { !!Object.keys(errors).length && !errors.fatal &&
                <Grid item xs={12}>
                  <Paper className="error" variant="outlined">
                    Please fix above errors before submitting.
                  </Paper>
                </Grid> 
              }
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplierApplicationFormPage;
