import {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

// https://medium.com/@geeky_writer_/using-react-hooks-to-create-awesome-forms-6f846a4ce57
const useApplicationForm = (callback) => {
  const history = useHistory();

  const [isRLSameAsHOAddress, setIsRLSameAsHOAddress] = useState(false);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect (() => {
    if (hasSubmitted) {
      let validationErrors = validate();
      setErrors(validationErrors);
    }
  }, [inputs]);

  const fields = {
    Title: true,
    TradeName: false,
    CorporateRegistrationNumber: true,
    HOStreet: true,
    HOCity: true,
    HOProvinceorState: true,
    HOCountry: true,
    RLStreet: false,
    RLCity: false,
    RLProvinceorState: false,
    RLCountry: false,
    SupplierName: true,
    SupplierTitle: true,
    SupplierEmailAddress: true,
    SupplierPhoneNumber_x0028_s_x002: true,
//    SubscribedToComplyWorks: true,
//    SubscribedToComplyWorksIdNumber: false,
    ConflictofInterest: true,
    ConflictofInterestExplanation: false,
    AcknowledgeReadRequirements: true,
    InputMaterialsAndBulkChemicals: false,
    ITTranspProAndFacServices: false,
    MroEquipmAndMachParts: false,
    PlantMaintAndSpecialtyServices: false,
    MaterialsOrServicesNotListed: false,
    ValueAddedServices: false,
    WhereCompanyRegistered: true,
    CanadaIdProvince: false,
    CompanyRegisteredOtherExplanatio: false,
    BankingPartnersName: true,
    BankingPartnersAddress: true,
    CurrencyCompanytransactIn: true,
    CurrencyOtherExplanation: false,
    CompanyAssociatedWithUS: true,
    AssociatedWithUSCompanyName: false,
    DoneBusinessWithSherritt: true,
    HaveQualityProgramAndManual: true,
    HaveHealthAndSafetySystem: true,
    HavePolicyOnEnvironmentalSteward: true,
    HaveSupplierDiversityGoals: true,
    HavePolicyOnHumanRights: true,
    OperateThroughConflictAreas: true,
    ConductHumanRightsDueDiligence: true,
    AffiliatedWithIndigenous: true,
    InvolvedInLegalCase: true,
    HaveEnvironmentalIncidents: true,
    EnviroIncidentsExplanation: false,
    MinimumRequirementsWithSuppliers: true,
  };

  const isEmpty = (value) => {
    return (value === undefined
            || value === null
            || (value.trim && value.trim().length === 0)
            || (value.results && value.results.length === 0));
  };

  const validate = () => {
    let errors = {};

    let errorText = 'Field is required';

    for (let key in fields) {
      if (fields.hasOwnProperty(key)) {
        let required = fields[key];
        let value = inputs[key];

        if (required && isEmpty(value)) {
          errors[key] = errorText;
        }
      }
    }

/*     if (inputs.SubscribedToComplyWorks && isEmpty(inputs.SubscribedToComplyWorksIdNumber)) {
      errors.SubscribedToComplyWorksIdNumber = errorText;
    } */

    if (inputs.ConflictofInterest && isEmpty(inputs.ConflictofInterestExplanation)) {
      errors.ConflictofInterestExplanation = errorText;
    }

    if (!isRLSameAsHOAddress) {
      if (isEmpty(inputs.RLStreet)) {
        errors.RLStreet = errorText;
      }
      if (isEmpty(inputs.RLCity)) {
        errors.RLCity = errorText;
      }
      if (isEmpty(inputs.RLProvinceorState)) {
        errors.RLProvinceorState = errorText;
      }
      if (isEmpty(inputs.RLCountry)) {
        errors.RLCountry = errorText;
      }
    }

    if (isEmpty(inputs.InputMaterialsAndBulkChemicals) &&
        isEmpty(inputs.ITTranspProAndFacServices) &&
        isEmpty(inputs.MroEquipmAndMachParts) &&
        isEmpty(inputs.PlantMaintAndSpecialtyServices) &&
        isEmpty(inputs.MaterialsOrServicesNotListed)) {
      errors.MaterialsOrServicesNotListed = errorText;
    }

    if (inputs.WhereCompanyRegistered === 'Other' && isEmpty(inputs.CompanyRegisteredOtherExplanatio)) {
      errors.CompanyRegisteredOtherExplanatio = errorText;
    }

    if (inputs.WhereCompanyRegistered === 'Canada' && isEmpty(inputs.CanadaIdProvince)) {
      errors.CanadaIdProvince = errorText;
    }

    if (inputs.CurrencyCompanytransactIn === 'Other' && isEmpty(inputs.CurrencyOtherExplanation)) {
      errors.CurrencyOtherExplanation = errorText;
    }

    if (inputs.CompanyAssociatedWithUS && isEmpty(inputs.AssociatedWithUSCompanyName)) {
      errors.AssociatedWithUSCompanyName = errorText;
    }

    if (!isEmpty(inputs.SupplierEmailAddress) && !/\S+@\S+\.\S+/.test(inputs.SupplierEmailAddress)) {
      errors.SupplierEmailAddress = 'Invalid email address';
    }

    if (inputs.HaveEnvironmentalIncidents && isEmpty(inputs.EnviroIncidentsExplanation)) {
      errors.EnviroIncidentsExplanation = errorText;
    }

    return errors;
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    setHasSubmitted(true);
    let validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    fetch('/SupplierApplication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inputs)

    }).then(function(response) {
      if (!response.ok) {
        throw new Error('Network error.');
      }

      history.push('/Supplier-Application-Form-Success');

    }).catch(function(e) {
      setErrors(errors => {
        errors.fatal = true;
        return errors;
      });

    }).then(function() {
      setSubmitting(false);
    });
  }

  const handleRegionalSameAsHeadChange = (event) => {
    setIsRLSameAsHOAddress(event.target.checked);

    const newInputs = {...inputs};
    if (event.target.checked) {
      setRLAddress(newInputs);
    } 
    setInputs(newInputs);
  }

  const setRLAddress = (newInputs) => {
    newInputs.RLStreet = newInputs.HOStreet;
    newInputs.RLCity = newInputs.HOCity;
    newInputs.RLProvinceorState = newInputs.HOProvinceorState;
    newInputs.RLCountry = newInputs.HOCountry;
  }

  const handleInputChange = (event) => {
    event.persist();

    let key = event.target.name;
    let value = event.target.value;

    if (!key) {
      throw new Error('Unnamed input');
    }

    const newInputs = {...inputs};
    switch (event.target.type) {
      // Single select
      case 'radio':
        if (value === 'Yes' || value === 'No') { // Boolean yes/no
          newInputs[key] = (value === 'Yes');

        } else { // Single choice
          newInputs[key] = value;
        }

        setInputs(newInputs);
        break;

      // Multi select
      case 'checkbox':
        let choiceItem = newInputs[key];
        if (!choiceItem) {
          choiceItem = {
            results: []
          };
        }

        if (event.target.checked) {
          choiceItem.results.push(value);
        } else {
          choiceItem.results = choiceItem.results.filter(e => e !== value);
        }

        if (choiceItem.results.length) {
          newInputs[key] = choiceItem;
        } else {
          delete newInputs[key];
        }

        setInputs(newInputs);
        break;

      default:
        if (typeof value !== 'string' || value.length === 0) {
          value = null;
        }

        if (value !== null) {
          newInputs[key] = value;
        } else {
          delete newInputs[key];
        }

        if (isRLSameAsHOAddress) {
          setRLAddress(newInputs);
        }
        setInputs(newInputs);
    
        break;
    }
  }

  return [
    inputs,
    errors,
    isRLSameAsHOAddress,
    isSubmitting,
    handleInputChange,
    handleRegionalSameAsHeadChange,
    handleSubmit
  ];
}

export default useApplicationForm;
