import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './BusinessWithSherrittPage.css';

const BusinessWithSherrittPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <h1>Doing Business with Sherritt</h1>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <h3>Qualify to be a Supplier</h3>

              <p>
                Sherritt works with many Suppliers who provide materials and services that meet our needs and align with <a href={process.env.REACT_APP_NAV_LINK_VISION_PURPOSE_AND_PROMISES}>Sherritt&#39;s Vision, Purpose and Promises</a>.
              </p>

              <p>
                <strong>Is your company interested in doing business with Sherritt?</strong> Learn more on how your company can be included on a list of Approved Suppliers by clicking the link below.
              </p>

              <p>
                <Link to="/Qualify-To-Be-A-Supplier">Read More</Link>
              </p>
            </Grid>

            <Grid item lg={6} md={8} sm={12} xs={12}>
              <h3>Supplier Resources</h3>

              <p>
                Find all the information you need as a potential or existing Supplier pertaining to Sherritt requirements for doing business with Sherritt.
              </p>

              <p>
                <Link to="/Supplier-Resources">Read More</Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessWithSherrittPage;
