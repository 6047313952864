import React from 'react';
import './NavInner.css';

const NavInner = (props) => {
  return (
    <div>
      <nav className="inner-navigation">
        <ul className="level1">
          <li className="expanded">
            <span>Apply to be a Supplier</span>
            <ul className="level2">
              {/* <li><a href="https://www.complyworks.com/" target="_blank">ComplyWorks</a></li> */}
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </li>
        </ul>
      </nav>

      <div className="qualify-form-container">
        <a href="/Supplier-Application-Form">Supplier Application Form</a>
      </div>
    </div>
  );
};

export default NavInner;
