import React from 'react';
import Grid from '@material-ui/core/Grid';
import './FAQsPage.css';

const FAQsPage = () => {
  return (
    <div className="page-content-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <h1>Qualify to be a Supplier FAQs</h1>
              <ol>
                <li>
                  <strong>I am a Supplier who is looking for opportunities to do business with Sherritt, what are the steps for qualifying to be a Supplier?</strong>
                  <div className="response-text">Response:</div>
                  <ol type="i">
                    <li>Review all requirements for doing business with Sherritt in the Supplier Resources area.</li>
                    <li>Complete the Supplier Application Form and submit online.</li>
                    <li>Your company’s Supplier Application Form will be reviewed internally by the Supply Chain Management 
                      Team to ensure your company’s capabilities align to Sherritt’s business needs.</li>
                    <li>If your company’s Supplier Application Form aligns to business needs your company will receive an invitation 
                      letter to register in the Pre-Qualification System.</li>
                  </ol>
                  <br/>
                  <p>Further information can be found in the section <a href="/Qualify-To-Be-A-Supplier">“Qualify to be a Supplier”</a> on the website</p>
                </li>
                <li>
                  <strong>I know someone who works at Sherritt, can I go through this person to try and obtain work?</strong>
                  <div className="response-text">Response:</div>
                  <p>No. If you are interested in doing business with Sherritt, we invite you to review our website.  
                    All potential Suppliers for materials and/or services should review and learn about the Sherritt 
                    Pre-Qualification process and requirements available on our website. This allows all potential Suppliers to 
                    understand Sherritt’s requirements and expectations from the same consistent and current source.</p>
                </li>
                <li>
                  <strong>When will I be notified if my company is invited to do business with Sherritt?</strong>
                  <div className="response-text">Response:</div>
                  <p>Due to the high volume of applications, only Suppliers whose capabilities match current business needs will be contacted and 
                    invited to register in the Pre-Qualification System.</p>
                </li>
                <li>
                  <strong>Is the information I provide through this application process shared outside Sherritt?</strong>
                  <div className="response-text">Response:</div>
                  <p>No. The information provided is for internal use only.</p>
                </li>
                <li>
                  <strong>How long does my Supplier Application Form remain in an active status?</strong>
                  <div className="response-text">Response:</div>
                  <p>The Supplier Application Form remains in an active status for 12 months.</p>
                </li>
              </ol>
              <br/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQsPage;
