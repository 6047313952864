import React from 'react';
import './NavFooter.css';

const NavFooter = () => {
  return (
    <div className="nav-footer-container">
      <div className="nav-footer-inner">
        <span>&copy; Copyright 2020 Sherritt International Corporation, </span>
        <span>All rights reserved. <a className="nav-footer-copyright" href={process.env.REACT_APP_NAV_LINK_DISCLAIMER}>Disclaimer</a>
</span>
      </div>
    </div>
  );
};

export default NavFooter;
