import React, { useState } from 'react';
import clsx from 'clsx';
import './NavMenu.css';
import closeIcon from './close-icon.png';

const NavMenu = (props) => {
  const [searchTerm, setSearchTerm] = useState(null);

  const onSearchSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    let term = searchTerm;
    if (term && term.trim) {
      term = term.trim();
    }

    if (term.length) {
      window.location = 'https://www.sherritt.com/English/Search-Results/default.aspx?SearchTerm=' + encodeURIComponent(term);
    }
  };

  return (
    <div className="nav-drawer">
      <div className={clsx('nav-inner', { 'nav-high-contrast': props.highContrast })}>
        <div className="nav-close-button">
          <img className="nav-clickable" onClick={props.onMenuToggle} src={closeIcon} alt="Close" />
        </div>

        <div className="nav-search">
          <form onSubmit={onSearchSubmit}>
            <input className="nav-search-text" type="text" onChange={(e) => setSearchTerm(e.target.value)} />
            <input className="nav-search-submit" type="submit" />
          </form>
        </div>

        <div className="nav-menu">
          <ul>
            <li><a href={process.env.REACT_APP_NAV_LINK_ABOUT_US}>About Us</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_DOING_BUSINESS_WITH_SHERRITT}>Doing Business with Sherritt</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_OPERATIONS}>Operations</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_TECHNOLOGIES}>Technologies</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_INVESTOR_RELATIONS}>Investor Relations</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_SUSTAINABILITY}>Sustainability</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_CONTACT}>Contact</a></li>
            <li><a href={process.env.REACT_APP_NAV_LINK_CAREERS}>Careers</a></li>
          </ul>
        </div>

        <div className="nav-high-contrast-toggle" onClick={props.onHighContrastToggle}>
          <div className="nav-high-contrast-icon">
          </div>
          <span title="Toggle high contrast view" role="button">
            High Contrast
          </span>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
